import DataObject from '@interfaces/DataObject.interface';
import GenericState from '@interfaces/GenericState.interface';
import GenericStore from '@interfaces/GenericStore.interface';
import CallResponse from '@interfaces/lila-call.interface';
import Step from '@interfaces/step.interface';
import auth from '@libs/lila-auth';
import { Call } from '@libs/lila-call';
import MainStore from '@store/main.store';
import { Route } from 'vue-router';

export interface ConfigStoreState extends GenericState {

  helpData: any[];
  step: any;
  steps: any[];
  index: number;

  showImpossible: boolean;
  complete: boolean;
  animation: string[];
  splash: {
    button?: string,
    modules?: any,
  };

  baseLink: string;

  helpActive: boolean;

  selectedData: string[];

  confirm: {
    text?: string,
    accept?: string,
  }

  mapping: Record<string, string>;

  isConcept: boolean;

  success: {
    route: Route,
    mergeValues?: boolean,
  }

  init: boolean;

  config?: any;
}

const ConfigStore: GenericStore<ConfigStoreState> = {
  namespaced: true,
  strict: true,

  state: {
    helpData: [],
    step: {},
    steps: [],
    index: null,

    showImpossible: false,
    complete: false,
    animation: [],

    splash: {},

    baseLink: '',
    helpActive: false,
    selectedData: [],
    confirm: {},

    mapping: null,

    success: null,

    isConcept: true,

    partial: null,

    init: false,

    config: null,

  },

  mutations: {

    setHelpData(state, data: any[]) {

      state.helpData = data;

    },

    setSteps(state, steps: Step[]) {

      state.steps = steps;

    },

    setStep(state, step: Step) {

      state.step = step;

    },

    setSettings(state: ConfigStoreState, settings: any): void {

      state.showImpossible = settings.showImpossible;
      state.animation = settings.animation;
      state.splash = settings.splash;
      state.mapping = settings.mapping;
      state.isConcept = settings.isConcept;
      state.success = settings.success;
      state.challenge = settings.challenge;

    },

    setComplete(state, complete: boolean) {

      state.complete = complete;

    },

    setBaseLink(state, link: string) {

      state.baseLink = link;

    },

    setIndex(state, index: number) {

      state.index = index;

    },

    setHelpActive(state, active: boolean) {

      state.helpActive = active;

    },

    setSelectedData(state, data: string[]) {

      state.selectedData = data;

    },

    setStepActive(state, data: {index: number, active: boolean}) {

      state.steps[data.index].active = data.active;

    },

    setStepValid(state, data: {index: number, valid: boolean}) {

      state.steps[data.index].valid = data.valid;

    },

    setConfirm(state, data: {text: string, accept: string}) {

      state.confirm = data;

    },

    init(state, init: boolean) {

      state.init = init;

    },

    partial(state, partial: any) {

      state.partial = partial;

    },

  },

  actions: {

    /**
     * get data from the /data endpoint
    */
    getPartial(store, name: string) {

      const call = new Call<any>(MainStore.getters.endpoints);

      return call.get('api', `/concept/partial/${name}`, {}, auth.getAuthHeader())
        .then((res) => store.commit('partial', res.r.partial))
        .catch((error) => {

          console.error(error.status);
          store.commit('partial', { status: error.status, error: true });

        });

    },

    getHelpData(store) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', '/data/content/help', {}, auth.getAuthHeader())
        .then((data: CallResponse<any>) => this.commit('Config/setHelpData', data.r))
        .catch((e) => {

          console.error(e);

        });

    },

    toggleHelp() {

      this.commit('Config/setHelpActive', !(this.state.Config.helpActive === true));

    },

    updateSelectedData(store, data: {value: string, index: number}) {

      const newData = [...store.state.selectedData];

      newData[data.index] = data.value;

      this.commit('Config/setSelectedData', newData);

    },

  },

  getters: {

    help: (state) => {

      if (!state.step.help) return null;
      if (!state.helpData.length) return null;

      return state.helpData.find((content) => content.id === state.step.help);

    },

  },

};

export default ConfigStore;
