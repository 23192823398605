import vue from 'vue';

/**
 *
 *
 * @export
 * @class loadElements
 */

export default (requireModules: __WebpackModuleApi.RequireContext, folder: string): void => {

  requireModules.keys().forEach((filename: string) => {

    const matches = filename.match(/([a-z-]+)\.(module|partial).(ts|vue)$/i);
    const componentName = `${matches[1]}-${matches[2]}`;

    /**
       * check if file is inside of his own folder
      */
    const inFolder = filename.indexOf(`./${matches[1]}/`) === 0;
    const importPath = inFolder ? `${matches[1]}/${matches[0]}` : matches[0];

    if (folder === 'modules') {

      vue.component(
        componentName,
        () => import(`../modules/${importPath}`),
      );

    }

    if (folder === 'partials') {

      vue.component(
        componentName,
        () => import(`../partials/${importPath}`),
      );

    }

  });

};
