import { Call } from '@libs/lila-call';
import auth from '@libs/lila-auth';
import MainStore from '@store/main.store';
import { replace } from '@mixins/replacePlaceholder';
import BaseCallData from '@interfaces/BaseCallData.interface';
import DataObject from '@interfaces/DataObject.interface';
import DataStoreState from '@interfaces/dataStore.interface';
import GenericStore from '@interfaces/GenericStore.interface';
import CallResponse from '@interfaces/lila-call.interface';

const DataStore: GenericStore<DataStoreState> = {
  namespaced: true,
  strict: true,

  state: {

    settings: {},
    project: null,
    data: {
      sites: {},
    },

    search: {
      tags: [],
      search: null,
    },

    filter: {
      verified: null,
    },

    site: null,
    filterActive: false,

    childDataCache: {},

  },

  mutations: {

    data(state, data) {

      state.data = data;

    },

    setSearch(state, search: { tags?: string[], search?: string }) {

      if (search.tags) {

        if (typeof search.tags === 'string') {

          state.search.tags = [search.tags];

        } else {

          state.search.tags = search.tags;

        }

      } else {

        state.search.tags = [];

      }

      search.search
        ? state.search.search = search.search
        : delete state.search.search;

    },

    updateSearch(state, update: { data: any, type: string }) {

      if (!update.data.length) {

        delete state.search[update.type];

      } else {

        state.search[update.type] = update.data;

      }

    },

    updateFilter(state, update: { data: any, type: string }) {

      if (!update.data) {

        delete state.filter[update.type];

      } else {

        state.filter[update.type] = update.data;

      }

    },

    setSite(state, site: number) {

      state.site = site || 0;

    },

    toggleFilter(state, active: boolean) {

      state.filterActive = active;

    },

    childDataCacheSingle(state, childData: {id: string, data: any}) {

      state.childDataCache[childData.id] = childData.data;

    },
  },

  actions: {

    /**
     * get data from the /data endpoint
    */
    get(store, id) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', `/data/${id}`, {}, auth.getAuthHeader())
        .then((res) => store.commit('data', replace(res.r)))
        .catch((error) => {

          console.error(error);
          store.commit('data', { status: error.status });

        });

    },

    /**
     * get data from the /data endpoint
    */
    getV2(store, id) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', `/data/${id}`, {}, auth.getAuthHeader())
        .then((res) => replace(res.r));

    },

    /**
     * get data from the /data endpoint
    */
    publicV2(store, id) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', `/data/public/${id}`, {}, auth.getAuthHeader())
        .then((res) => replace(res.r));

    },

    /**
     * get data from the /data endpoint
    */
    contentV2(store, id) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', `/data/content/${id}`, {}, auth.getAuthHeader())
        .then((res) => replace(res.r));

    },

    public(store, id) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', `/data/public/${id}`, {}, auth.getAuthHeader())
        .then((res) => store.commit('data', replace(res.r)))
        .catch((error) => {

          console.error(error);
          store.commit('data', { status: error.status });

        });

    },

    content(store, id) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', `/data/content/${id}`, {}, auth.getAuthHeader())
        .then((res) => store.commit('data', replace(res.r)))
        .catch((error) => {

          store.commit('data', { status: error.status });
          throw error;

        });

    },

    cache(store, id) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      if (store.state.childDataCache[id]) return null;

      return call.get('api', `/data/${id}`, {}, auth.getAuthHeader())
        .then((res) => store.commit('childDataCacheSingle', { id, data: replace(res.r) }))
        .catch((error) => {

          console.error(error);
          store.commit('data', { status: error.status });

        });

    },

    getChallengeContent(store, { challenge, id }) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', `/challenge/${challenge}/${id}`, {}, auth.getAuthHeader())
        .then((res) => store.commit('data', replace(res.r)))
        .catch((error) => {

          console.error(error);
          store.commit('data', { status: error.status });

        });

    },

    /**
     * get data from a different endpoint
    */
    getEndpoint(store, data: {settings: BaseCallData, query?: any}) {

      let urlArray = [];
      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      urlArray.push(data.settings.endpoint);

      if (data.settings.add) {

        urlArray = [...urlArray, ...data.settings.add];

      }

      console.log(urlArray, MainStore.getters.endpoints);
      return call.get('api', `/${urlArray.join('/')}`, data.query, auth.getAuthHeader());

    },

    getConceptPreview(store, conceptId: string) {

      const call = new Call<CallResponse<any>>(MainStore.getters.endpoints);

      return call.get('api', `/concept/${conceptId}/preview`, {}, auth.getAuthHeader());

    },

    getConcept(store, conceptId: string) {

      const call = new Call<CallResponse<any>>(MainStore.getters.endpoints);

      return call.get('api', `/concept/${conceptId}`, {}, auth.getAuthHeader());

    },

    listConcepts(store, settings: {search?: string, site?: number, limit?: number}) {

      const query: Record<string, string | number> = {};

      if (settings.search) query.search = settings.search as string;
      if (settings.limit) query.limit = settings.limit;

      const call = new Call<CallResponse<any>>(MainStore.getters.endpoints);

      return call.get('api', `/concept/list/${settings.site || 1}`, query, auth.getAuthHeader());

    },

    save(store, data) {

      let url: string;

      if (data.type !== 'new') {

        url = `/data/${data.company}/${data.project}/content/${data._id}`;

      } else {

        url = `/data/${data.company}/${data.project}/content`;
        delete data._id;

      }

      const call = new Call<CallResponse<any>>(MainStore.getters.endpoints);

      return call.post('api', url, { data }, auth.getAuthHeader());

    },

  },

};

export default DataStore;
