import { Call } from '@libs/lila-call';
import auth from '@libs/lila-auth';
import MainStore from '@store/main.store';
import GenericStore from '@interfaces/GenericStore.interface';
import CallResponse from '@interfaces/lila-call.interface';
import ChallengeStoreState from './ChallengeStore';

const ChallengeStore: GenericStore<ChallengeStoreState> = {
  namespaced: true,
  strict: true,

  state: {

    choices: null,
    partial: null,
    content: null,
    enrolled: false,
    challenge: null,
    concept: null,

  },

  mutations: {

    partial(state, data) {

      state.partial = data;

    },

    challenge(state, data) {

      state.challenge = data;

    },

    choices(state, data) {

      state.choices = data;

    },

    content(state, data) {

      state.content = data;

    },

    status(state, data) {

      state.enrolled = data;

    },

  },

  actions: {

    /**
     * get data from the /data endpoint
    */
    getPartial(store, params) {

      const call = new Call<any>(MainStore.getters.endpoints);

      return call.get('api', `/challenge/${params.challenge}/partial/${params.part}`, {}, auth.getAuthHeader())
        .then((res) => {

          store.commit('partial', res.r.partial);

          return store.dispatch('getChallenge', params.challenge);

        })
        .catch((error) => {

          console.error(error.status);
          store.commit('partial', { status: error.status, error: true, data: error.r.data });

        });

    },

    getContent(store, params: { challenge, id }) {

      const call = new Call<any>(MainStore.getters.endpoints);

      return call.get('api', `/challenge/${params.challenge}/${params.id}`, {}, auth.getAuthHeader())
        .then((res) => {

          store.commit('content', res.r.content);

          return store.state.enrolled ? store.dispatch('getChallenge', params.challenge) : true;

        })
        .catch((error) => {

          console.error(error);
          store.commit('content', { status: error.status, error: true });

        });

    },

    getStatus(store, params: { challenge, id }) {

      const call = new Call<any>(MainStore.getters.endpoints);

      return call.head('api', `/challenge/${params.challenge}/status`, {}, auth.getAuthHeader())
        .then((res) => store.commit('status', res.status === 200))
        .catch((error) => {

          console.error(error);
          store.commit('status', false);

        });

    },

    getChallenge(store, challenge) {

      const call = new Call<any>(MainStore.getters.endpoints);

      return call.get('api', `/challenge/${challenge}`, {}, auth.getAuthHeader())
        .then((res) => {

          store.commit('choices', res.r.choices);
          store.commit('challenge', res.r);

          return true;

        })
        .catch((error) => {

          console.error(error);
          store.commit('choices', {});
          store.commit('challenge', null);

        });

    },

    createShare(store, challenge) {

      const call = new Call<any>(MainStore.getters.endpoints);

      return call.post('api', `/concept/challenge/${challenge.partial.challenge}`, {}, auth.getAuthHeader());

    },

    save(store, data) {

      let url: string;

      if (data.type !== 'new') {

        url = `/data/${data.company}/${data.project}/content/${data._id}`;

      } else {

        url = `/data/${data.company}/${data.project}/content`;
        delete data._id;

      }

      const call = new Call<CallResponse<any>>(MainStore.getters.endpoints);

      return call.post('api', url, { data }, auth.getAuthHeader());

    },

    signup(store, challenge) {

      const call = new Call<CallResponse<any>>(MainStore.getters.endpoints);

      return call.post('api', `/challenge/${challenge}/signup`, {}, auth.getAuthHeader());

    },

  },

};

export default ChallengeStore;
