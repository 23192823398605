






































































import { ExtComponent, Component, Watch } from '@libs/lila-component';
import { Call } from '@libs/lila-call';
import Configurator from '@libs/lila-configurator';
import Resize from '@libs/lila-resize';
import auth from '@libs/lila-auth';
import { Store } from 'vuex';
import Concept from '@classes/concept.class';
import { Route } from 'vue-router';
import log from 'loglevel';
import translate from '@mixins/translation';
import { Dictionary } from 'vue-router/types/router';
import prepareContent from '@mixins/prepareContent';

@Component
export default class ConfigScreen extends ExtComponent {

  config: Configurator = new Configurator();

  complete = false;

  currentIndex = 0;

  showSplash = false;

  showAnimation = false;

  showAddModule = false;

  componentName = 'config-screen';

  @Watch('$route.params')
  onRouteChange(): void {

    this.setup();
    this.updateSelectedData();
    this.update();

  }

  get configAnimation() {

    return this.$store.state.Config.animation;

  }

  get steps() {

    return this.$store.state.Config.steps;

  }

  get showImpossible() {

    return this.$store.state.Config.showImpossible;

  }

  get init() {

    return this.$store.state.Config.init;

  }

  get dataName() {

    return this.$store.state.Config.partial?.name;

  }

  get params() {

    const params: Record<string, string> = {};

    if (this.$store.state.Config.mapping) {

      return this.mapParams(this.$store.state.Config.mapping, this.$route.params);

    }


    return this.$route.params;

  }

  get prevObject() {

    console.log(Object.keys(this.config.prevParams).length, this.config.backButton);

    return this.config.backButton ? this.config.prevParams : { name: this.$route.name, params: this.config.prevParams };

  }

  get nextObject() {

    let params = {};

    if (this.$store.state.Config.mapping) {

      // switch keys and values to remap
      const base = Object.assign(
        {},
        ...Object.entries(this.$store.state.Config.mapping as Record<string, string>)
          .map(([a, b]) => ({
            [b]: a,
          })),
      );

      params = this.mapParams(base, this.config.nextParams);

    }

    return { name: this.$route.name, params: { ...this.config.nextParams, ...params } };

  }

  get concept() {

    return this.$store.state.concept;

  }

  get help() {

    return prepareContent(this.$store.getters['Config/help']);

  }

  get helpActive() {

    return this.$store.state.Config.helpActive;

  }

  get selectedData() {

    return this.$store.state.Config.selectedData;

  }

  get percentage() {

    const percentage = (this.currentIndex / this.$store.state.Config.steps.length) * 100;

    return { width: `${percentage.toFixed(2)}%` };

  }

  get confirm() {

    if (!this.$store.state.Config.confirm) return false;
    if (!this.$store.state.Config.confirm.text) return false;

    return this.$store.state.Config.confirm;

  }

  get settings() {

    return this.$store.state.Config;

  }

  get error() {

    return this.$store.state.Config.partial?.error ? this.$store.state.Config.partial : false;

  }

  toggleSplash(): void {

    this.showSplash = !this.showSplash;

  }

  mapParams(base: Record<string, string>, values: Record<string, string>) {

    const params: Record<string, string> = {};

    Object.keys(base).forEach((key) => {

      const value = base[key];

      console.log(value, key, params[value]);

      params[value] = values[key];

    });

    return params;

  }

  /**
   *
   *
   * @memberof ConfigScreen
   */
  created(): void {

    if (this.$route.name === 'challenge') {

      if (this.$store.state.Challenge.partial.status === 403) {

        if (this.$store.state.Challenge.partial?.data.route) {

          this.$router.push(this.$store.state.Challenge.partial?.data.route);

        }

        return;

      }

    }

    this.setup();
    this.updateSelectedData();
    this.update();

    // hide splash if we are not start at the beginning
    if (this.selectedData.length > 0) this.showSplash = false;

  }

  mounted(): void {

    console.log(this.$route);

    // if (this.$route.path === '/config') {
    //   this.$router.replace('/');
    // }

    this.$store.dispatch('getData', { link: 'config' });
    this.$store.dispatch('Config/getHelpData', {});

    this.realHeight = Resize.realHeight;

    window.addEventListener('resized', () => {

      this.realHeight = Resize.realHeight;

    });

  }

  // constructor() {

  //   super();

  //   const data = { steps: [] };

  //   this.data = this.$store.state.partials.config;
  //   this.$store.commit('Config/setSteps', this.$store.state.partials.config.steps);
  //   this.$store.commit('Config/setBaseLink', '/config');

  //   this.$store.state.partials.config.steps.map((step: Step) => {

  //     data.steps.push(step);

  //   });

  //   this.$store.commit('Config/setSettings', this.$store.state.partials.config);

  //   this.config = new Configurator();
  //   this.config.baseLink = '/config';

  // }

  beforeDestroy() {

    this.$store.commit('Config/init', false);

  }

  setup() {

    if (this.$store.state.Config.init) return;

    const partial = this.getPartial(this.$route);
    const challenge = this.$store.state.Challenge;

    console.warn(challenge, partial);

    const redirect = this.validateChallengeSteps(challenge, partial);

    if (redirect) {

      this.$store.commit('Config/init', false);
      return this.$router.push(redirect);

    }


    if (!this.error) {

      this.$store.commit('Config/setSteps', partial.steps);
      this.$store.commit('Config/setSettings', partial);

    }


    this.$store.commit('Config/init', true);

  }

  validateChallengeSteps(challenge: any, partial: any) {

    log.info(challenge, partial);

    /**
     * check if this step was already answered
    */
    if (challenge.challenge?.steps && challenge.challenge?.steps[this.$route.params.part] && challenge.partial.redo === false) {

      log.error('already done');
      if (partial.success.route) return partial.success.route;


    }

    if (+this.$route.params.part > 1) {

      let part = 2;
      let redirectRoute;

      if (!challenge.challenge.steps) return redirectRoute = { params: { ...this.$route.params, part: 1 } };

      while (part < +this.$route.params.part) {

        if (!challenge.challenge.steps[part]) {

          log.error(`previous step ${part} missing`, part.toString());
          redirectRoute = { params: { ...this.$route.params, part: part.toString() } };
          break;

        }

        part += 1;

      }

      if (redirectRoute) return redirectRoute;

    }

    return null;

  }

  getPartial($route) {

    let partial;

    if (this.$route.name === 'config') partial = this.$store.state.Config.partial;
    if (this.$route.name === 'concept') partial = this.$store.state.Config.partial;

    if (this.$route.name === 'challenge') {

      partial = this.$store.state.Challenge.partial;
      if (!partial) throw new Error(`challenge "${$route.params.challenge}" part ${$route.params.part} partial not found`);

    }

    return partial;

  }


  choose(data: {value: string, index: number, confirm?: {text: string, accept: string}}) {

    console.log(this.selectedData, this.currentIndex, data.confirm);

    this.$store.dispatch('Config/updateSelectedData', { value: data.value, index: data.index });

    if (this.$store.state.Config.confirm !== data.confirm) {

      this.$store.commit('Config/setConfirm', data.confirm);

    }

    this.config.validate(this.selectedData);
    this.config.updatePossibleOptions(this.$store.state.Config.steps);

  }

  confirmAccept() {

    this.$store.commit('Config/setConfirm', {});

  }

  /**
   *
   *
   * @returns
   * @memberof ConfigScreen
   */
  update() {

    if (this.error) {

      console.error(this.error);
      return;

    }

    this.config.validate(this.selectedData);
    this.config.updatePossibleOptions(this.$store.state.Config.steps);
    this.config.selectCurrentStep(this.selectedData);
    this.config.setPrevLink(this.selectedData);

    this.complete = this.config.complete;
    this.currentIndex = this.$store.state.Config.index;

    if (this.$store.state.Config.helpActive) this.$store.commit('Config/setHelpActive', false);

    if (this.complete) {

      if (this.configAnimation) this.showAnimation = true;

      if (this.$store.state.Config.isConcept) {

        this.createConcept();

      } else {

        console.log('NOT A CONCEPT');
        this.updateChallenge();

      }

    }

  }

  updateChallenge() {

    let choices;
    const call = new Call(this.$store.getters.endpoints);
    const PROMISEARRAY: Promise<any>[] = [];

    choices = this.settings.mapping
      ? this.mapParams(this.settings.mapping, this.$route.params)
      : this.$route.params;

    choices.name = translate.translations[translate.current].SHARED_CONCEPT_NAME;

    call.post('api', `/challenge/${this.$route.params.challenge}/${this.$route.params.part}`, { choices }, auth.getAuthHeader())
      .then((response) => {


        console.log(response, this.$store.state.Challenge.partial.createShare);

        if (this.$store.state.Challenge.partial.createShare) {

          PROMISEARRAY.push(this.$store.dispatch('Challenge/createShare', this.$store.state.Challenge));

        }

        if (this.configAnimation) {

          PROMISEARRAY.push(new Promise((resolve) => setTimeout(() => { resolve(true); }, 5500)));

        }


        if (this.settings.success?.route) {


          return Promise.all(PROMISEARRAY).then(() => {

            this.$router.push(this.settings.success?.route);

          });

        }


      })
      .catch((e) => {

        console.error(e);

      });


  }

  createConcept() {

    let path = '/concept';
    let pushRoute = 'concept_overview';
    const conceptData: any = {
      ...this.$route.params,
      bedSize: +this.$route.params.bedSize,
    };

    if (!conceptData.style) {

      path = '/concept/empty';
      pushRoute = 'concept_edit';

    }

    const PROMISES = [];
    const call = new Call(this.$store.getters.endpoints);

    PROMISES.push(call.post('api', path, { ...conceptData, group: 'bloom' }, auth.getAuthHeader()));

    if (this.configAnimation) {

      PROMISES.push(new Promise((resolve: any, reject: any) => {

        setTimeout(() => {

          resolve();

        }, 8500);

      }));

    }

    Promise.all(PROMISES)
      .then((data: any) => {

        const concept = new Concept({ ...data[0].r, ...conceptData });

        this.$router.push({ name: pushRoute, params: { id: data[0].r._id } });

        this.showAnimation = false;

        return true;

      })
      .catch((e) => {

        console.log('error', e);
        this.showAnimation = false;

      });

  }

  /**
   *
   *
   * @memberof ConfigScreen
   */
  updateSelectedData(): void {

    this.$store.commit('Config/setSelectedData', this.config.filterSelectedData(this.params));

  }

  //   /**
  //  *
  //  *
  //  * @param {*} params
  //  * @param {*} [store]
  //  * @returns {(Promise<any>|true)}
  //  * @memberof ConfigScreen
  //  */
  //   asyncData(to: Route, store?: Store<MainStoreState>): Promise<any> | true {

  //     if (to.name === 'challenge') {

  //       return store.dispatch('Challenge/getPartial', to.params);

  //     }

  //     if (to.meta?.config) {

  //       store.dispatch('Config/getPartial', to.name);

  //     }

  //     console.log(store, to);
  //     if (store.state.data.modules) return new Promise((resolve) => resolve(null));

  //     return store.dispatch('getData', { link: 'config' })
  //       .then(() => store.dispatch('getConfigHelp', {}));

  //   }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string|string[]>, store: Store<MainStoreState>, to: Route) {

    if (to.name === 'challenge') {

      return store.dispatch('Challenge/getPartial', to.params);

    }

    if (to.meta?.config) {

      store.dispatch('Config/getPartial', to.name);

    }

    console.log(store, to);
    if (store.state.data.modules) return new Promise((resolve) => resolve(null));

    return store.dispatch('getData', { link: 'config' })
      .then(() => store.dispatch('getConfigHelp', {}));

  }

  // // eslint-disable-next-line class-methods-use-this
  // preloadDataPost(preloadedData, params: Dictionary<string>, query: Dictionary<string|string[]>, store: Store<MainStoreState>, to: Route) {

  //   if (preloadedData) store.commit('Data/data', preloadedData);
  //   return new Promise<void>((resolve) => resolve());

  // }

  toggleHelp() {

    this.$store.dispatch('Config/toggleHelp');

  }

}

