var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "challenge-screen screen" },
    [
      _vm.enrolled
        ? _c("content-module", { attrs: { content: _vm.content } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.enrolled && _vm.enrollChallenge && !_vm.signupIndicator
        ? _c("content-module", { attrs: { content: _vm.enrollChallenge } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.signupIndicator
        ? _c(
            "section",
            { staticClass: "container content" },
            [
              _c(
                "generic-module",
                [
                  !_vm.enrolled && !_vm.error
                    ? _c(
                        "button-partial",
                        {
                          staticClass: "callToAction",
                          on: { confirmed: _vm.signup }
                        },
                        [_vm._v("An der kostenlosen Challenge teilnehmen")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.signupIndicator
        ? _c("spinner-partial", { attrs: { colorScheme: "colorScheme1" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c(
            "error-module",
            _vm._b(
              {
                attrs: { overlay: "", register: "" },
                on: { verified: _vm.emailVerified }
              },
              "error-module",
              _vm.error,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }