













import {
  ExtComponent, Component,
} from '@libs/lila-component';

@Component
export default class appComponentPartial extends ExtComponent {

  get heightStyle() {

    return {
      '--height': `${this.realHeight}px`,
    };

  }

}
