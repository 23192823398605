import store from '@store/main.store';
import { ConfigStoreState } from '@store/config.store';
import Step from '@base/@types/step';

export default class Configurator {

  baseLink = '';

  store: ConfigStoreState = store.state.Config;

  nextParams = {};

  prevParams = {};

  get steps(): Step[] {

    return this.store.steps;

  }

  get step(): Step {

    return this.store.step;

  }

  get link(): string {

    return '';

  }

  get backButton(): boolean {

    return !!this.step.back;

  }

  get complete(): boolean {

    return this.store.complete;

  }

  filterSelectedData(unfilteredParams: Record<string, string>): string[] {

    const paramArray = Object.keys(unfilteredParams).map((value) => unfilteredParams[value]);

    return paramArray.filter((value) => !!value);

  }

  /**
     * updates all steps recording to the input date
     *
     * @param {string[]} selectedData
     * @memberof Configurator
     */
  validate(selectedData: string[]): void {

    let stateIndex = 0;

    this.nextParams = {};
    this.steps.forEach((step: Step, index: number) => {

      const linkAdd: string = selectedData.slice(0, index).join('/');
      const next = this.getStep(index + 1);

      step.input = this.matchInput(index, selectedData);
      step.valid = this.validateStep(step, step.input);

      step.link = linkAdd.length > 0
        ? `${this.link}/${linkAdd}`
        : `${this.link}${linkAdd}`;

      if (step.valid) {

        this.nextParams[step.name] = selectedData[index];

        step.active = false;
        if (next) {

          // next.active = true;
          if (store.state.Config.steps[index + 1].active === false) {

            store.commit('Config/setStepActive', { index: index + 1, active: true });

          }

          stateIndex = index + 1;

        }

      } else {

        if (!index) {

          // step.active = true;
          if (store.state.Config.steps[index].active === false) {

            store.commit('Config/setStepActive', { index, active: true });

          }

          stateIndex = index;

        }
        if (next) {

          // next.active = false;
          if (store.state.Config.steps[index + 1].active === true) {

            store.commit('Config/setStepActive', { index: index + 1, active: false });

          }

        }

      }

    });

    // this.nextLink = `${this.store.step.link}/${this.store.step.input}`;

    if (this.store.index !== stateIndex) {

      store.commit('Config/setIndex', stateIndex);

    }

    this.checkComplete();

  }

  selectCurrentStep(selectedData: string[]): void {

    const step = this.steps[selectedData.length];

    console.log(step, selectedData);

    if (step) {

      store.commit('Config/setStep', step);

    } else {

      store.commit('Config/setStep', {});

    }

  }

  setPrevLink(selectedData: string[]): void {

    if (this.store.index === 0) {

      this.prevParams = this.step.back ? this.step.back : {};
      return;

    }

    const toObject = {};

    this.steps.slice(0, selectedData.length - 1).forEach((step, index) => {

      if (selectedData[index]) toObject[step.name] = selectedData[index];

    });

    this.prevParams = toObject;

  }

  validateStep(step: Step, input: string): boolean {

    const valid = input && step
      ? this.checkOptionValid(input, step.options, step.validation)
      : false;

    return valid;

  }

  matchInput(index: number, data: string[]): string {

    return data[index];

  }

  getStep(index: number): { [key: string]: any } {

    if (!this.steps) return null;
    if (!this.steps[index]) return null;

    return this.steps[index];

  }

  checkOptionValid(value: string, possibleValues: unknown[], validation: string): boolean {

    if (!possibleValues && !validation) return false;

    if (validation) {

      return !!value.match(new RegExp(validation));

    }

    return !!possibleValues.find((possible: any) => {

      let valid: boolean;

      if (possible.require) {

        possible.require.forEach((requireOptions: string[], index: number) => {

          const step = this.getStep(index);

          if (!requireOptions.length) return;
          if (!step) return;
          if (!valid) return;

          valid = requireOptions.indexOf(step.input) !== -1;

        });

      } else {

        valid = true;

      }

      return (possible.value === value && valid);

    });

  }

  updatePossibleOptions(steps: { [key: string]: any }[]): void {

    steps.forEach((step: any, index: number) => {

      if (!step) return;

      step.options.forEach((option: any) => {

        if (!option) return;

        option.possible = !option.require
          ? true
          : this.checkOptionValid(option.value, step.options, step.validation);

      });

    });

  }

  checkComplete(): void {

    const setComplete = this.steps.filter((step) => !step.valid).length === 0;

    if (setComplete !== this.store.complete) store.commit('Config/setComplete', setComplete);

  }

  get completePercent() {

    return this.steps.length / this.store.index;

  }

  get isLastStep(): boolean {

    return this.store.step === this.steps[this.steps.length - 1];

  }

  get isFirstStep(): boolean {

    return this.store.step === this.steps[0];

  }

}
export {
  store,
};
