import { Call } from '@libs/lila-call';
import auth from '@libs/lila-auth';
import MainStore from '@store/main.store';
import Vue from 'vue';
import GenericStore from '@interfaces/GenericStore.interface';
import SettingsStoreState from './SettingsStoreState';

const SettingsStore: GenericStore<SettingsStoreState> = {
  namespaced: true,
  strict: true,

  state: {
    settings: {},
  },

  mutations: {

    update(state, setting: {[key: string]: string}) {

      Object.keys(setting).map((key) => {

        const value = setting[key];

        Vue.set(state.settings, key, value);

        return setting;

      });

    },

    remove(state, key: string) {

      Vue.delete(state.settings, key);

    },

    removeMulti(state, keys: string[]) {

      keys.map((key) => {

        Vue.delete(state.settings, key);

        return key;

      });

    },

    clean(state) {

      state.settings = {};

    },

  },

  actions: {

    syncDown(store, data) {

      console.log('GET SETTINGS', store, data, store.state);

      const call = new Call(MainStore.getters.endpoints);

      return call.get('api', `/project/${data.company}/${data.project}/settings`, {}, auth.getAuthHeader());

    },

    syncUp(store, data) {

      const call = new Call(MainStore.getters.endpoints);

      return call.post('api', '/me/settings/sync', data.settings, auth.getAuthHeader());

    },

    update(store, data) {

      const call = new Call(MainStore.getters.endpoints);

      store.commit('update', data);
      return call.post('api', '/me/settings', data, auth.getAuthHeader());

    },

  },

};

export default SettingsStore;
