import ChildData from '@interfaces/ChildData.interface';
import Content from '@interfaces/Content.interface';
import ContentWithPositions from '@interfaces/ContentWithPositions.interface';
import filterMergeModules from './filterMergeModules';
import hardCopy from './hardCopy';

const addNamespace = (modules: Content['modules'], namespace = 'lila') => {

  modules?.forEach((single) => {

    // check if already in this namespace
    if (single.type?.match(new RegExp(`${namespace}-`))) return;
    single.type = `${namespace}-${single.type}`;

    // namespace also child modules of this module
    if (single.modules) addNamespace(single.modules, namespace);

  });

};
const distributeChildData = (modules: Content['modules'], childData: ChildData) => {

  modules.forEach((singleModule) => {

    if (!singleModule.childData?.index) return;

    const newData: Record<string, Content> = {};

    singleModule.childData.index?.forEach((single) => {

      newData[single] = childData?.data[single];

    });

    singleModule.childData.data = newData;

  });

};

export default (content: Partial<Content>, layout?: Content, namespace = 'lila') => {

  const namespacedContent: Partial<Content> = hardCopy(content);

  // if (namespacedContent?.modules) addNamespace(namespacedContent?.modules, namespace);

  if (namespacedContent?.modules && content?.childData) {

    distributeChildData(namespacedContent?.modules, content?.childData);

  }

  const combinedContent: ContentWithPositions = {
    id: content?.id,
    settings: namespacedContent?.settings || {},
    top: namespacedContent?.modules ? filterMergeModules(namespacedContent?.modules, 'top', layout?.modules) : [],
    content: namespacedContent?.modules ? filterMergeModules(namespacedContent?.modules, 'content', layout?.modules) : [],
    bottom: namespacedContent?.modules ? filterMergeModules(namespacedContent?.modules, 'bottom', layout?.modules) : [],
  };

  return combinedContent;

};
