var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.init
    ? _c(
        "div",
        {
          key: "config-screen-" + _vm.$route.name,
          staticClass: "module configuration-screen screen",
          style: { "min-height": _vm.realHeight + "px" }
        },
        [
          _vm.error
            ? _c(
                "error-module",
                _vm._b(
                  { attrs: { register: "", upgrade: "", overlay: "" } },
                  "error-module",
                  _vm.error,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "exit close button",
              attrs: { to: { name: "me-home" } }
            },
            [_c("span"), _vm._v(" "), _c("span")]
          ),
          _vm._v(" "),
          !_vm.error
            ? _c("section", { staticClass: "progress-container" }, [
                _c("div", {
                  staticClass: "progress-bar",
                  style: _vm.percentage
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.configAnimation
            ? _c(
                "section",
                {
                  staticClass: "splash-container animation",
                  class: { visible: _vm.showAnimation && _vm.complete }
                },
                [
                  _c(
                    "div",
                    { staticClass: "content-container" },
                    [
                      _c(
                        "generic-module",
                        _vm._l(_vm.animation.text, function(text, index) {
                          return _c("h2", { key: "a-text-" + index }, [
                            _vm._v(_vm._s(text))
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm._m(0)
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.complete || (_vm.complete && _vm.showAnimation)
            ? _vm._l(_vm.steps, function(step, index) {
                return _c("step-module", {
                  key: "step-" + index + "-" + _vm.dataName,
                  class: {
                    visible:
                      !_vm.showSplash ||
                      _vm.currentIndex > 0 ||
                      (_vm.complete && _vm.showAnimation)
                  },
                  attrs: {
                    currentIndex: _vm.currentIndex,
                    index: index,
                    content: step,
                    showImpossible: _vm.showImpossible
                  },
                  on: { set: _vm.choose }
                })
              })
            : _vm._e(),
          _vm._v(" "),
          _c("transition", [
            _vm.helpActive && _vm.help
              ? _c(
                  "article",
                  { staticClass: "help-container" },
                  [
                    _c("header", [
                      _c("h3"),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "base transparent",
                          on: {
                            click: function($event) {
                              return _vm.toggleHelp()
                            }
                          }
                        },
                        [_vm._v("HILFE SCHLIEßEN")]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.help.modules
                      ? _c("content-module", { attrs: { content: _vm.help } })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.showSplash && !_vm.complete && !_vm.error
            ? _c(
                "section",
                { staticClass: "config-controls" },
                [
                  _c(
                    "router-link",
                    {
                      class: {
                        active: !_vm.config.isFirstStep || _vm.config.backButton
                      },
                      attrs: { to: _vm.prevObject }
                    },
                    [_vm._v(_vm._s(_vm._f("translate")("back")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "help",
                      class: { hidden: !_vm.help },
                      on: {
                        click: function($event) {
                          return _vm.toggleHelp()
                        }
                      }
                    },
                    [_vm._v("HILFE")]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      class: {
                        active: _vm.config.step.valid && !_vm.confirm,
                        complete: _vm.config.isLastStep
                      },
                      attrs: { to: _vm.nextObject }
                    },
                    [_vm._v(_vm._s(_vm._f("translate")("next")))]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { mode: "out-in", name: "confirm" } }, [
            _vm.confirm
              ? _c("section", { staticClass: "config-confirm" }, [
                  _c("section", { staticClass: "confirm-container" }, [
                    _c("p", [_vm._v(_vm._s(_vm.confirm.text))]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button-base colorScheme2",
                        on: { click: _vm.confirmAccept }
                      },
                      [_vm._v(_vm._s(_vm.confirm.accept))]
                    )
                  ])
                ])
              : _vm._e()
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "bounce1" }),
      _vm._v(" "),
      _c("div", { staticClass: "bounce2" }),
      _vm._v(" "),
      _c("div", { staticClass: "bounce3" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }