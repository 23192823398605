import Concept from '@base/classes/concept.class';
import ConceptExport from '@interfaces/conceptExport.interface';
import DataObject from '@interfaces/DataObject.interface';
import CallResponse from '@interfaces/lila-call.interface';
import { Call } from '@libs/lila-call';
import MainStore from '@store/main.store';

declare interface SharedStore {

  concept: ConceptExport;
}

const SharedStore = {
  namespaced: true,
  strict: true,

  state: {
    concept: {},
  },

  mutations: {

    concept(state, data: ConceptExport) {

      state.concept = data;

    },

  },

  actions: {

    getConcept(store, id: string) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', `/shared/${id}`, {})
        .then((data: CallResponse<any>) => {

          const concept = new Concept(data.r.data);

          concept.createIndex();
          concept.refreshGroups();

          console.log('shared', data);
          return store.commit('concept', concept.export());

        })
        .catch((e) => {

          console.error(e);

        });

    },

    preview(store, id: string) {

      const call = new Call<CallResponse<DataObject<any>>>(MainStore.getters.endpoints);

      return call.get('api', `/shared/${id}/preview`, {});

    },

  },

};

export default SharedStore;
